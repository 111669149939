<template>
	<div class="opacity-page show">
		<div class="header-filter" v-if="loaded">
			<div class="row-space-tbf search-row">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)"/></div>
				<div class="content">
					<div class="search-input">
						<input type="text" :placeholder="$t('general.search_by')" v-model="search_filter" v-debounce:500="searchFilterFunct"/>
					</div>
					<div class="actions-page mobile">
						<button v-if="$auth.user().rights.admins_crud" class="btn-tbf blue" @click="$auth.user().rights.can_create_user_limit ? $router.push({name: 'user-create'}) : showModal('warning_limit_package')">
							<div class="icon">
								<icon-plus />
							</div>
							<span class="text" v-if="$resize && $mq.above(600)">{{ $t('profile.add_user') }}</span>
						</button>
						<div class="actions actions-dropdown">
						<div class="actions-page mobile">
							<button v-if="$resize && $mq.above(600)" class="btn-tbf grey filter-btn" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
								<div class="counter" v-bind:class="{active: queryFiltersCount > 0}">{{ queryFiltersCount }}</div>
								<span class="text">{{ $t('general.filters') }}</span>
								<div class="icon-filter">
									<icon-arrow />
								</div>
							</button>
							<button v-else-if="$resize && $mq.below(599)" class="btn-tbf grey filter-btn-mobile" v-bind:class="{active: extendFilters}" @click="extendFilters = !extendFilters">
								<div class="icon"><icon-settings /></div>
							</button>
						</div>
					</div>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
			<div class="row-space-tbf filters-extend" :class="[{mobile: $resize && $mq.below(500)}]" v-if="extendFilters">
				<div class="space-left"></div>
				<div class="content">
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedRoles.length">{{ $t('filters.by_roles') }}</span>
							<span class="text" v-else-if="selectedRoles.length == 1">{{ filterRoles.find(el => el.id == selectedRoles[0]).name }}</span>
							<span class="text" v-else>{{ selectedRoles.length }} {{ $t('filters.roles_selected') }}</span>

							<div class="icon-filter" v-if="!selectedRoles.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedRoles')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchRoles" :placeholder="$t('filters.search_roles')">
							</div>

							<template v-for="role in filteredRoles">
								<div class="dropdown-item" @click.stop="selectOptionFilter('selectedRoles', role.id)" v-bind:class="{active: selectedRoles.includes(role.id)}" :key="'filter-role-' + role.id">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ role.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedStatus">{{ $t('filters.by_status') }}</span>
							<span class="text" v-else>{{ $t(`user_archivate.status.${selectedStatus}`) }}</span>

							<div class="icon-filter" v-if="!selectedStatus"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedStatus')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<template v-for="status in usersStatusList">
								<div class="dropdown-item" @click.stop="selectSingleOptionFilter('selectedStatus', status)" v-bind:class="{active: selectedStatus.includes(status)}" :key="'filter-status-' + status">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ $t(`user_archivate.status.${status}`) }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
						<icon-trash v-if="$resize && $mq.above(501)"/>
						<div v-else-if="$resize && $mq.below(500)">{{$t('filters.clear_all')}}</div>
					</button>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
		<loader-header-list v-else/>

		<template v-if="loadedList">
			<div class="data-list list-goals users" v-if="users.length">
				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter sortable column-name" @click="sortList('first_name')" v-bind:class="[sortBy == 'first_name' ? 'sort ' + sortDirection : '']">
							<div class="text">{{ $t('general.name') }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-roles">
							<div class="text">{{ $t('procedures.roles') }}</div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-rights">
							<div class="text">{{ $t('users.rights') }}</div>
						</div>
						<div v-if="$resize && $mq.above(700)" class="column-filter column-nr-processes">
							<div class="text">{{ $t('general.processes') }}</div>
						</div>
						<div v-if="$resize && $mq.above(1100)" class="column-filter column-nr-procedures">
							<div class="text">{{ $t('general.procedures') }}</div>
						</div>
						<div v-if="$resize && $mq.above(1100)" class="column-filter column-nr-procedures">
							<div class="text">{{ $t('users.procedures_responsible') }}</div>
						</div>
						<div class="column-filter column-edit-mobile" v-if="$resize && $mq.below(500)"></div>
					</div>
					<div class="space-right"></div>
				</div>
				<template v-for="user in users">
					<div class="row-space-tbf row-list-item" :key="`userItem${user.id}`">
						<div class="space-left"><icon-people v-if="$resize && $mq.above(781)"/></div>
						<div class="content">
							<div v-if="$resize && $mq.above(781)" class="border-hover"></div>
							<div class="column-name-desc column-avatar column-name" @click="$router.push({name: 'user-show', params: {slug: user.slug} })">
								<img :src="user.avatar" v-if="user.avatar">
								<div class="user-circle" v-else>
									<icon-user />
								</div>
								<div class="text">
									<div class="name">{{ user.first_name + ' ' + (user.last_name ? user.last_name : '') }}</div>
									<div class="description" v-if="!user.dummy_account">{{ user.email }}</div>
								</div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-simple-text column-roles">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
									<div class="text roles">
										<span class="first" v-if="user.roles.length == 1">{{ user.roles[0].name }}</span>
										<span v-else-if="user.roles.length > 1">{{`${user.roles[0].name} (+${(user.roles.length - 1)})`}}</span>
									</div>
									<template slot="popover">
										<div class="simple-text"><span v-for="(role, index) in user.roles">{{index < user.roles.length - 1 ? role.name + ', ' : role.name}}</span></div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-simple-text column-rights">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{show: 200, hide: 0}" >
									<div class="text roles">
										<span class="first" v-if="user.rights_name.length == 1">{{ $t(`users.rights_name.${user.rights_name[0]}`) }}</span>
										<span v-else-if="user.rights_name.length > 1">{{ $t(`users.rights_name.${user.rights_name[0]}`) + ` (+${(user.rights_name.length - 1)})`}}</span>
									</div>
									<template slot="popover">
										<div class="simple-text">{{ user.rights_name.map((el) => { return $t(`users.rights_name.${el}`) }).join(", ") }}</div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && $mq.above(700)" class="column-simple-text column-nr-processes">
								<div class="text">{{user.processes}}</div>
							</div>
							<div v-if="$resize && $mq.above(1100)" class="column-simple-text column-nr-procedures">
								<div class="text">{{user.procedures_roles}}</div>
							</div>
							<div v-if="$resize && $mq.above(1100)" class="column-simple-text column-nr-procedures">
								<div class="text">{{user.procedures_responsible}}</div>
							</div>
							<div class="column-edit-mobile" v-if="$resize && $mq.below(500)">
								<div class="dropdown dropdown-edit">
									<div class="button-edit" :ref="'dropdownEditRef'+user.id" :id="'dropdownEdit'+user.id" data-toggle="dropdown">
										<icon-edit-mobile />
									</div>
									<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ user.id " :aria-labelledby="'dropdownEdit'+user.id">
										<div class="dropdown-item" @click="$router.push({name: 'user-show', params: {slug: user.slug} })"><div class="simple-text">{{$t('general.view')}}</div></div>
										<div class="dropdown-item" @click="editUser(user.slug)" v-if="user.rights.edit"><div class="simple-text">{{$t('general.edit')}}</div></div>
										<div class="dropdown-item" @click="showModal('reset_password', {slug: user.slug, email: user.email})" v-if="user.rights.reset_password"><div class="simple-text">{{$t('general.reset_password_link')}}</div></div>
										<div class="dropdown-item" @click="showModal('delete', {type: 'user', from: 'index_users', model: user})" v-if="user.rights.delete"><div class="simple-text">{{$t('general.delete')}}</div></div>
									</div>
								</div>
							</div>
						</div>
						<div class="space-right">
							<div v-if="$resize && $mq.above(501)" class="dropdown dropdown-edit">
								<div class="button-edit" :ref="'dropdownEditRef'+user.id" :id="'dropdownEdit'+user.id" data-toggle="dropdown">
									<icon-edit />
								</div>
								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu'+ user.id " :aria-labelledby="'dropdownEdit'+user.id">
									<div class="dropdown-item" @click="$router.push({name: 'user-show', params: {slug: user.slug} })"><div class="simple-text">{{$t('general.view')}}</div></div>
									<div class="dropdown-item" @click="editUser(user.slug)" v-if="user.rights.edit"><div class="simple-text">{{$t('general.edit')}}</div></div>
									<div class="dropdown-item" @click="showModal('reset_password', {slug: user.slug, email: user.email})" v-if="user.rights.reset_password"><div class="simple-text">{{$t('general.reset_password_link')}}</div></div>
									<div class="dropdown-item" @click="showModal('confirm_archiving', {userSlug: user.slug, status: user.status })" v-if="user.rights.delete"><div class="simple-text">{{ user.status == 'active' ? $t('general.archived') : $t('general.activate') }}</div></div>
									<div class="dropdown-item" @click="showModal('delete', {type: 'user', from: 'index_users', model: user})" v-if="user.rights.delete"><div class="simple-text">{{$t('general.delete')}}</div></div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<!-- <div class="icon-empty">
						<img src="/build/images/no-results-found.svg">
					</div> -->
					<div class="title">{{ $t('empty.title')}}</div>
					<div class="description" v-html="$t('empty.users_text')"></div>

					<div class="add-button" v-if="$auth.user().rights.admins_crud">
						<button class="btn-tbf blue center" @click="$router.push({name: 'user-create'})">
							<div class="icon"><icon-plus class="white" /></div>
							<div class="text">{{ $t('profile.add_user')}}</div>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-items-list class="position-absolut-list-loader" v-else/>

		<infinite-loading :identifier="infiniteId" @infinite="infiniteUsersHandler" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
	</div>
</template>

<script>
    import IconSearch from '../../Icons/Search'
    import IconPlus from '../../Icons/Plus'
    import IconArrow from '../../Icons/Arrow'
    import IconUser from '../../Icons/User'
    import IconPeople from '../../Icons/People'
    import IconEdit from '../../Icons/EditDots'
	import IconEditMobile from '../../Icons/Edit'
	import IconTrash from '../../Icons/Trash'
	import IconClose from '../../Icons/Close'
	import IconSettings from '../../Icons/Settings'
    import LoaderItemsList from '../../PagesLoaders/ItemsList'
    import LoaderHeaderList from '../../PagesLoaders/HeaderList'
	import InfiniteLoading from 'vue-infinite-loading'

    export default {
    	components: {
            IconSearch,
            IconPlus,
            IconArrow,
            IconUser,
            IconPeople,
            IconEdit,
			IconEditMobile,
			IconTrash,
			IconClose,
			IconSettings,
            LoaderItemsList,
            LoaderHeaderList,
			InfiniteLoading
        },
        data() {
            return {
            	loaded: false,
            	loadedList: false,
            	search_filter: '',
            	users: [],
            	sortBy: 'first_name',
            	sortDirection: 'asc',
				page: 0,
				infiniteId: 1,
				queryFiltersCount: 0,
            	extendFilters: true,
            	filterRoles: [],
            	selectedRoles: [],
            	searchRoles: '',
            	usersStatusList: ['active', 'archived'],
            	selectedStatus: ''
            }
        },
        async mounted(){
        	this.$refs.infiniteLoading.status = 1
			this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)

			await this.getFilters()
        	this.checkQueryFilters()

			setTimeout(() => {
				var title = this.$t('navbar.users');
				this.$root.$emit("navbar_title", title);
			}, 0)

			this.$root.$on('refreshUsersIndex', () => {
				this.getFilters()
				this.checkQueryFilters()
				this.searchFilterFunct()
			})
        },
        watch: {
        	search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
        },
        computed: {
			filteredRoles(){
				return getByKeywordFilter(this.filterRoles, this.searchRoles)
			},
        },
        methods: {
			async getFilters(){
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {roles: true}})
				.then(({data}) => {
					this.filterRoles = data.data.roles
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { this.loaded = true })
			},
			infiniteUsersHandler($state){
				var paramsCall = { view_more: this.page * 20}
        		this.queryObject(paramsCall)

        		if(this.sortBy){
        			paramsCall.sort_by = this.sortBy
        			paramsCall.sort_direction = this.sortDirection
        		}

        		axios.get(this.$auth.user().instance.id + '/users', {params: paramsCall})
        		.then(({data}) => {
        			if(data.data.length){
        				this.page++
        				this.users.push(...data.data)
        				setTimeout(() => { $state.loaded() }, 200)
        				if(data.data.length < 20){
        					$state.complete()
        				}
        			}else{
        				$state.complete()
        			}

        			this.loadedList = true
        		}).catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
        		})
        	},
			queryObject(paramsCall){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ paramsCall.search = dataQuery[key] }
					if(key == 'roles'){ paramsCall.role_ids = dataQuery[key].split(',').map(Number) }
					if(key == 'status'){ paramsCall.status = dataQuery[key].split(',') }
				});
			},
			selectOptionFilter(filter, value) {
				if(typeof value == 'string'){
					if(this[filter].includes(value)){
						this[filter].splice(this[filter].indexOf(value), 1)
					}else{
						this[filter].push(value)
					}
				} else {
					if(this[filter].includes(parseInt(value))){
						this[filter].splice(this[filter].indexOf(parseInt(value)), 1)
					}else{
						this[filter].push(parseInt(value))
					}
				}

				this.addFilterToRouteQuery(filter)
        	},
        	selectSingleOptionFilter(filter, value){
        		if(this[filter] != value) {
	        		this[filter] = value
        		}else{
        			this[filter] = ''
        		}
        		
        		this.addFilterToRouteQuery(filter)
        	},
        	addFilterToRouteQuery(filterName){
				var dataQuery = Object.assign({}, this.$route.query);

				if(filterName == 'selectedRoles' || filterName == 'all'){ dataQuery['roles'] = this.selectedRoles.toString(); }
				if(filterName == 'selectedStatus' || filterName == 'all'){ dataQuery['status'] = this.selectedStatus.toString(); }

				Object.keys(dataQuery).map(function(key, value) {
					if(dataQuery[key] == ""){
						delete dataQuery[key]
					}
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length

				this.$router.push({query : dataQuery });
				this.searchFilterFunct()
			},
			clearFilter(filter){
				if(filter == 'all'){
					this.selectedRoles = []
					this.selectedStatus = ''
				}else{
					if(filter == 'selectedStatus') {
						this[filter] = ''
					} else {
						this[filter] = []
					}
				}

				this.addFilterToRouteQuery(filter)
			},
			checkQueryFilters(){
				var dataQuery = Object.assign({}, this.$route.query);

				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ this.search_filter = dataQuery[key] }
					if(key == 'roles'){ this.selectedRoles = dataQuery[key].split(',').map(Number) }
					if(key == 'status'){ this.selectedStatus = dataQuery[key].split(',') }
				});

				this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length
			},
			sortList(column){
				if(column === this.sortBy) {
                    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
                }
                this.sortBy = column;

				this.searchFilterFunct()
			},
			editUser(userSlug){
				this.$router.push({ name: 'user-edit', params: {slug: userSlug} })
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			searchFilterFunct(){
				this.loadedList = false
				this.page = 0
				this.users = []
				this.$refs.infiniteLoading.status = 1
				this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)
			},
        }
    }
	function getByKeywordFilter(list, keyword){
		const search = keyword.trim()

		if (!search.length) return list
		return list.filter(item => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
	}
</script>

<style lang="scss">
	.list-goals.users{
		.row-list-item{
			&:hover{
				.content{
					.column-name{
						.name{
							font-weight: 700;
						}
					}
				}
			}
		}
		.column-avatar {
			&.column-name {
				.user-circle,
				img {
					margin-right: 20px;
					min-width: 36px;
				}
				.text{
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}
			}
		}
		.column-roles{
			flex: 0 0 180px;
			.roles{
				display: flex;
				padding-right: 15px;
				.word{
					padding-right: 10px;
				}
				.number{
					padding-right: 10px;
					text-align: center;
					min-width: 57px;
					line-height: 16px;
					padding-bottom: 3px;
					margin: auto;
				}
			}
		}
		.column-rights{
			flex: 0 0 150px;
		}
		.column-name{
			flex: 1 1 auto;
			display: flex;
			cursor: pointer;
			align-items: center;
		}
		.column-nr-processes,
		.column-nr-procedures{
			flex: 0 0 100px;
		}
		.column-user{
			flex: 0 0 90px;
			display: flex;
			justify-content: flex-end;
		}
		@media (max-width: 500px) {
			.column-user.mobile{
				margin-right: 15px;
				max-width: 50px;
				width: 20px;
				display: flex;
				justify-content: center;
			}
		}
	}
</style>